import { computed, watch } from 'vue';
export const useType3 = (info, rules, productsAmount, greenProductsAmount) => {
  const promotions = computed(() => {
    if (info?.value?.type === 2) {
      return rules.value?.promotions.sort((a, b) => a.pieces - b.pieces);
    }

    return rules.value?.promotions?.[0] || {};
  });
  const group1 = reactive({
    // 幾件
    pieces: computed(() => {
      return productsAmount.value;
    }),
    // 多少錢
    price: computed(() => {
      return productsAmount.value * Number(promotions.value.price);
    }),
    formatPrice: computed(() => {
      return window._number_format(group1.price);
    }),
    // 是否可以送出
    canSend: computed(() => {
      return !(group1.pieces === 0);
    })
  });
  const group2 = reactive({
    // 可以選擇的數量
    promotions: computed(() => {
      return promotions.value.find(item => item.pieces === productsAmount.value);
    }),
    // 可以選擇的數量
    nowPromotions: computed(() => {
      const promotionsList = promotions.value.filter(item => item.pieces <= productsAmount.value);
      return promotionsList[promotionsList.length - 1];
    }),
    // 下一階段的優惠
    nextPromotions: computed(() => {
      const promotionsList = promotions.value.filter(item => item.pieces > productsAmount.value);
      return promotionsList[0] || promotions.value[promotions.value.length - 1];
    }),
    // 幾件
    pieces: computed(() => {
      return productsAmount.value;
    }),
    // 還少幾件
    fewPieces: computed(() => {
      return Number(group2.nextPromotions?.pieces) - group2.pieces;
    }),
    // 多少錢
    price: computed(() => {
      return Number(group2.nowPromotions?.price) || 0;
    }),
    formatPrice: computed(() => {
      return window._number_format(group2.price);
    }),
    // 是否符合滿件 才可以送出
    canSend: computed(() => {
      return group2.promotions;
    })
  });
  const group3 = reactive({
    group: computed(() => {
      return Math.max(group3.pieces, group3.pieces_green);
    }),
    pieces: computed(() => {
      return Math.ceil(productsAmount.value / Number(promotions.value.pieces));
    }),
    fewPieces: computed(() => {
      return group3.group * Number(promotions.value.pieces) - productsAmount.value;
    }),
    pieces_green: computed(() => {
      return Math.ceil(greenProductsAmount.value / Number(promotions.value.pieces_green));
    }),
    fewPiecesGreen: computed(() => {
      return group3.group * Number(promotions.value.pieces_green) - greenProductsAmount.value;
    }),
    price: computed(() => {
      return group3.group * Number(promotions.value.price);
    }),
    formatPrice: computed(() => {
      return window._number_format(group3.price);
    }),
    // 是否可以送出
    canSend: computed(() => {
      return !(group3.group === 0 || group3.fewPieces > 0 || group3.fewPiecesGreen > 0);
    })
  });
  const group = computed(() => {
    if (info?.value?.type === 1) {
      return group1;
    }

    if (info?.value?.type === 2) {
      return group2;
    }

    if (info?.value?.type === 3) {
      return group3;
    }

    return {};
  });
  const rulesCheck = computed(() => {
    // 限購數量
    const buyLimit = Number(rules.value.buy_limit); // 會員此團購歷史購買數量

    const userHistoryBuyNum = Number(rules.value.user_history_buy_num); // 成團上限(0=無上限)

    const stockLimit = Number(rules.value.stock_limit); // 此團購歷史購買數量

    const historyBuyNum = Number(rules.value.history_buy_num); // type === 3 紅綠配
    // 幾件

    let pieces = info.value.type === 3 ? Number(group.value.group) : Number(group.value.pieces); // 剩餘數量

    const lastQuantity = stockLimit - historyBuyNum; // 會員限購剩餘數量

    const lastMemberNum = buyLimit - userHistoryBuyNum - pieces; // 會員成團上限剩餘數量

    const lastStockNum = lastQuantity - pieces; // 會員剩餘可以購買的數量

    const lastNum = stockLimit === 0 ? lastMemberNum : Math.min(lastMemberNum, lastStockNum); // 是否已超過限購數量

    const isLimitHasBeenExceeded = buyLimit - userHistoryBuyNum < pieces; // 是否超出剩餘數量

    const isLastQuantity = stockLimit !== 0 && lastQuantity < pieces;
    const lastRedNum = (lastNum + pieces) * Number(promotions.value.pieces) - productsAmount.value;
    const lastGreenNum = (lastNum + pieces) * Number(promotions.value.pieces_green) - greenProductsAmount.value;
    return {
      // 是否已超過限購數量
      isLimitHasBeenExceeded,
      // 是否超出剩餘數量
      isLastQuantity,
      // 會員限購剩餘數量
      lastMemberNum,
      // 會員成團上限剩餘數量
      lastStockNum,
      // 會員剩餘可以購買的數量
      lastNum,
      lastRedNum,
      lastGreenNum,
      // 已經選幾件
      pieces
    };
  });
  return {
    group,
    rulesCheck
  };
};